<template>
	<div class="viewer-config">
		<div class="keybinds columns">
			<div v-for="hotkey in availableHotkeys" :key="hotkey.name" class="key">
				<label>{{ hotkey.description }}</label>
				<hotkey-select
					:value="hotkey.keyBinding"
					:disabled-keys="usedKeys(hotkey)"
					@input="updateHotkey(hotkey.name, $event)"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { handledHotkeys } from '@store/modules/viewerHotkeys.js'
import HotkeySelect from './HotkeySelect.vue'
import { setLoading } from '../../components/Loading.vue'

export default {
	name: 'HotkeyConfiguration',
	components: { HotkeySelect },
	data() {
		return {
			activeTab: 'leveling',
			activeModality: 'Default',
		}
	},
	computed: {
		...mapState({
			allHotkeys: state => state.viewerHotkeys.allHotkeys,
		}),
		...mapGetters(['mprActive']),
		availableHotkeys() {
			return this.allHotkeys.filter(k => handledHotkeys[k.name])
		},
	},
	mounted() {
		// TODO: get this once on login, ideally? This is just to get the list of all DB Hotkeys if they haven't been loaded yet.
		if (!this.allHotkeys || !this.allHotkeys.length) {
			this.$store.dispatch('setHotkeys', this.$ga)
		}
	},
	beforeDestroy() {
		// Rebind actions that may have changed
		if (this.mprActive) {
			this.$store.dispatch('setMPRHotkeys')
		} else {
			this.$store.dispatch('setHotkeys')
		}
	},
	methods: {
		updateHotkey(name, keyBinding) {
			let used = this.usedKeys({ name })
			if (keyBinding !== 'None' && used.includes(keyBinding)) {
				setLoading('Key is already used', Promise.resolve())
				return
			}
			this.$store.dispatch('modifyHotkeyAction', { name, keyBinding })
		},
		usedKeys(hotkey) {
			return this.availableHotkeys
				.filter(key => key.name !== hotkey.name)
				.map(key => key.keyBinding)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';
.columns {
	columns: auto;
	column-width: 275px;
	column-gap: 2em;
}

// Works, but only row-first. Boo.
// .grid {
// 	display: grid;
// 	grid-column-gap: 2em;
// 	grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
// }

.key {
	display: flex;
	flex-direction: row;
	break-inside: avoid;
	margin-bottom: 0.75em;
	align-items: center;
	position: relative;
	@media (min-width: $mqSmall) {
		margin-bottom: 0.5em;
	}
	label {
		flex-grow: 1;
		margin-right: 1em;
	}
}
</style>
