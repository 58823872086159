<template>
	<section class="teleconsultation-menu-settings">
		<h4 style="margin-bottom: 16px">Teleconsultation</h4>
		<p style="padding-bottom: 16px;">
			<svg-icon icon="info-circle" class="is-info" />
			The first item in the list will be opened by default on login.
		</p>
		<div>
			<button class="btn btn-default" @click="addFilterPreset">
				Add new quick filter &hellip;
			</button>
		</div>
		<reorderable-list v-model="menuItems" list-key="settingName" @input="save">
			<template #default="{ item, index }">
				<span :class="{ 'is-hidden': !item.isVisible }">
					{{ item.name }}
					<svg-icon v-if="index === 0" icon="home" class="is-info" style="margin-left: 4px;" />
				</span>
			</template>
			<template #buttons="{ item, index }">
				<button v-if="item.isCustomPreset" title="Delete" class="btn btn-default" @click="deleteItem(index)">
					<svg-icon icon="delete" />
				</button>
				<button
					v-if="item.isCustomPreset"
					title="Edit"
					class="btn btn-default"
					@click="editFilterPreset(item.settingName)"
				>
					<svg-icon icon="edit" />
				</button>
				<button
					v-if="!item.isCustomPreset"
					class="btn btn-default"
					:title="item.isVisible ? 'Hide' : 'Show'"
					@click="toggleVisibility(item)"
				>
					<svg-icon :icon="item.isVisible ? 'eye-slash' : 'eye'" />
				</button>
			</template>
		</reorderable-list>
	</section>
</template>

<script>
import clone from '@utils/deepClone'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import { openFilterPresetDlg } from '@dialogs/FilterPreset.vue'
import { menu as menuService } from '@services/menu'
import ReorderableList from '@components/ReorderableList.vue'

export default {
	name: 'TeleconsultationMenuSettings',
	components: { ReorderableList },
	data() {
		return {
			menuItems: [],
			filterPresets: [],
		}
	},
	created() {
		this.menuItems = clone(menuService.menuItems.teleconsultation)
		this.filterPresets = clone(menuService.menuSettings.teleconsultation.filterPresets) || []
	},
	methods: {
		toggleVisibility(item) {
			item.isVisible = !item.isVisible
			this.save()
		},
		async deleteItem(i) {
			if (!(await showConfirm('Are you sure you want to delete this item?'))) return
			const item = this.menuItems[i]
			this.filterPresets.splice(this.filterPresets.findIndex(p => p.id === item.settingName), 1)
			this.menuItems.splice(i, 1)
			this.save()
		},
		async addFilterPreset() {
			const newPreset = await openFilterPresetDlg()
			if (!newPreset) return
			this.filterPresets.push(newPreset)
			this.menuItems.push({
				name: newPreset.name,
				to: `/teleconsultations/?filter-preset=${newPreset.id}`,
				settingName: newPreset.id,
				isAvailable: true,
				isVisible: true,
				isCustomPreset: true,
			})
			this.save()
		},
		async editFilterPreset(id) {
			const presetIndex = this.filterPresets.findIndex(p => p.id === id)
			const updatedPreset = await openFilterPresetDlg(this.filterPresets[presetIndex])
			if (!updatedPreset) return
			this.filterPresets.splice(presetIndex, 1, updatedPreset)
			const menuItem = {
				name: updatedPreset.name,
				to: `/teleconsultations/?filter-preset=${updatedPreset.id}`,
				settingName: updatedPreset.id,
				isAvailable: true,
				isVisible: true,
				isCustomPreset: true,
			}
			const menuItemIndex = this.menuItems.findIndex(m => m.settingName === updatedPreset.id)
			this.menuItems.splice(menuItemIndex, 1, menuItem)
		},
		save() {
			menuService.saveMenuSettings('teleconsultation', this.menuItems, this.filterPresets)
		},
	},
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';

.teleconsultation-menu-settings {
	display: flex;
	position: relative;
	flex-direction: column;
	ol {
		max-width: 600px;
		padding: 16px 0;
		span.is-hidden {
			color: var(--icon-danger);
			text-decoration: line-through;
		}
	}
}
</style>
