var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"study-folder-settings"},[_c('h4',{staticStyle:{"margin-bottom":"16px"}},[_vm._v("Study Folders")]),(!_vm.folders.length)?_c('div',{staticStyle:{"display":"flex","flex-direction":"column","align-items":"center","justify-content":"center"}},[_c('p',[_c('svg-icon',{staticClass:"is-info",attrs:{"icon":"info-circle"}}),_vm._v("\n\t\t\tFolders can be used to categorize your studies.\n\t\t")],1),_c('svg-icon',{staticStyle:{"margin":"16px","font-size":"4em","opacity":"0.3"},attrs:{"icon":"folder-outline"}}),_c('button',{staticClass:"btn btn-default",on:{"click":_vm.addNewFolder}},[_vm._v("\n\t\t\tAdd your first folder …\n\t\t")])],1):_vm._e(),(_vm.folders.length)?[_c('p',{staticStyle:{"padding-bottom":"16px"}},[_c('svg-icon',{staticClass:"is-info",attrs:{"icon":"info-circle"}}),_vm._v("\n\t\t\tThese changes affect all users in your organization.\n\t\t")],1),_c('div',[_c('button',{staticClass:"btn btn-default",on:{"click":_vm.addNewFolder}},[_vm._v("\n\t\t\t\tAdd new folder …\n\t\t\t")])]),_c('reorderable-list',{attrs:{"list-key":"id"},on:{"input":_vm.save},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var parent = ref.item;
return [_c('span',[_vm._v(_vm._s(parent.name))])]}},{key:"buttons",fn:function(ref){
var parent = ref.item;
return [_c('button',{staticClass:"btn btn-default",attrs:{"title":"Delete"},on:{"click":function($event){return _vm.deleteFolder(parent)}}},[_c('svg-icon',{attrs:{"icon":"delete"}})],1),_c('button',{staticClass:"btn btn-default",attrs:{"title":"Edit"},on:{"click":function($event){return _vm.editFolder(parent)}}},[_c('svg-icon',{attrs:{"icon":"edit"}})],1)]}},{key:"inner",fn:function(ref){
var parent = ref.item;
return [(parent.children && parent.children.length)?_c('reorderable-list',{attrs:{"list-key":"id"},on:{"input":_vm.save},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var child = ref.item;
return [_c('svg-icon',{staticStyle:{"opacity":"0.5","margin-right":"8px"},attrs:{"icon":"folder-outline"}}),_c('span',[_vm._v(_vm._s(child.name))])]}},{key:"buttons",fn:function(ref){
var child = ref.item;
return [_c('button',{staticClass:"btn btn-default",attrs:{"title":"Delete"},on:{"click":function($event){return _vm.deleteFolder(child, parent.children)}}},[_c('svg-icon',{attrs:{"icon":"delete"}})],1),_c('button',{staticClass:"btn btn-default",attrs:{"title":"Edit"},on:{"click":function($event){return _vm.editFolder(child, parent.children)}}},[_c('svg-icon',{attrs:{"icon":"edit"}})],1)]}}],null,true),model:{value:(parent.children),callback:function ($$v) {_vm.$set(parent, "children", $$v)},expression:"parent.children"}}):_vm._e()]}}],null,false,208238844),model:{value:(_vm.folders),callback:function ($$v) {_vm.folders=$$v},expression:"folders"}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }