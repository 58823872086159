












import { mapGetters } from 'vuex'
import RootMenuSettings from './NavigationMenu/Root.vue'
import TeleconsultationMenuSettings from './NavigationMenu/TeleconsultationFilters.vue'
import StudyFoldersSettings from './NavigationMenu/StudyFolders.vue'

export default {
	name: 'NavigationMenuSettings',
	components: {
		RootMenuSettings,
		TeleconsultationMenuSettings,
		StudyFoldersSettings,
	},
	props: {
		menu: {
			type: String,
			default: null,
		},
	},
	computed: {
		...mapGetters(['isConsultantUser']),
	},
	mounted() {
		if (this.menu) {
			const section = this.$el.querySelector('#' + this.menu)
			if (section) section.scrollIntoView()
		}
	},
}
