<template>
	<section v-if="menuItems.length > 1" class="root-menu-settings">
		<reorderable-list v-model="menuItems" @input="save" />
	</section>
</template>

<script>
import ReorderableList from '@components/ReorderableList.vue'
import clone from '@utils/deepClone'
import { menu as menuService } from '@services/menu'

export default {
	name: 'RootMenuSettings',
	components: {
		ReorderableList,
	},
	data() {
		return {
			menuItems: [],
		}
	},
	created() {
		let menuItems = clone(menuService.menuItems.root)
		if (!this.$store.state.auth.claims.isSchedulingEnabled) {
			menuItems = menuItems.filter(i => i !== 'Schedule')
		}
		if (!this.$store.getters.isConsultantUser && !this.$store.state.static.consultants.length) {
			menuItems = menuItems.filter(i => i !== 'Teleconsultation')
		}
		this.menuItems = menuItems
	},
	methods: {
		save() {
			menuService.saveMenuSettings('root', this.menuItems)
		},
	},
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';

.root-menu-settings {
	display: flex;
	position: relative;
	flex-direction: column;
	.mouse-only {
		display: none;
		@media not all and (hover: none) {
			display: initial;
		}
	}
	ol {
		padding: 16px 0;
		max-width: 350px;
	}
}
</style>
