<template>
	<modal-wrapper :close-on-overlay-click="false" @close="close">
		<div class="dialog filter-preset">
			<dlg-header title="Filter Preset" :show-close="false" />
			<div class="filters">
				<div>
					<label>
						Name of Filter Preset
						<span class="is-required"></span>
					</label>
					<input v-model="filterPreset.name" type="text" class="input" style="max-width: 200px;" />
				</div>
				<div>
					<label>Status</label>
					<div>
						<button
							class="btn"
							:class="filterPreset.isComplete === 'Open' ? 'btn-success' : 'btn-default'"
							@click="filterPreset.isComplete = 'Open'"
						>
							Open
						</button>
						<button
							class="btn"
							:class="filterPreset.isComplete === 'Complete' ? 'btn-success' : 'btn-default'"
							@click="filterPreset.isComplete = 'Complete'"
						>
							Complete
						</button>
						<button
							class="btn"
							:class="!['Open', 'Complete'].includes(filterPreset.isComplete) ? 'btn-success' : 'btn-default'"
							@click="filterPreset.isComplete = 'Any'"
						>
							All
						</button>
					</div>
				</div>
				<div>
					<label>Pending Review</label>
					<div>
						<button
							class="btn"
							:class="filterPreset.isDraft === 'Yes' ? 'btn-success' : 'btn-default'"
							@click="filterPreset.isDraft = 'Yes'"
						>
							Yes
						</button>
						<button
							class="btn"
							:class="filterPreset.isDraft === 'No' ? 'btn-success' : 'btn-default'"
							@click="filterPreset.isDraft = 'No'"
						>
							No
						</button>
						<button
							class="btn"
							:class="!['Yes', 'No'].includes(filterPreset.isDraft) ? 'btn-success' : 'btn-default'"
							@click="filterPreset.isDraft = 'Any'"
						>
							All
						</button>
					</div>
				</div>
				<div>
					<label>Modality</label>
					<modality-select v-model="modalityId" nullable />
				</div>
				<!-- Type (Service/Priority) -->
				<div v-if="showTeleconsultationScheduleFiltering" style="font-size: 0.9em; letter-spacing: 0.05em;">
					<label>Type</label>
					<div class="radio-group">
						<ast-radio
							v-for="[requestType, guid] in requestTypes"
							:id="`request-type-${guid}`"
							:key="guid"
							:value="guid"
							:model="filterPreset.requestType"
							@input="filterPreset.requestType = $event"
						>
							{{ requestType }}
						</ast-radio>
					</div>
				</div>
				<div v-if="groupMembers.length > 2">
					<label>Claimed By</label>
					<v-select
						:value="filterPreset.claimedBy"
						label="name"
						:options="groupMembers"
						:reduce="o => o.id"
						:searchable="false"
						:clear-search-on-select="false"
						@input="filterPreset.claimedBy = $event"
					/>
				</div>
				<div v-if="clientClinics.length">
					<label>Requested By</label>
					<v-select
						:value="filterPreset.requestedBy"
						label="name"
						:options="clientClinics"
						:reduce="o => o.clinicCode"
						:searchable="false"
						:clear-search-on-select="false"
						@input="filterPreset.requestedBy = $event"
					/>
				</div>
				<div v-if="regions.length">
					<label>Region</label>
					<v-select
						:value="filterPreset.region"
						:options="Object.keys(regions)"
						:reduce="k => regions[k]"
						:searchable="false"
						:clear-search-on-select="false"
						@input="filterPreset.region = $event"
					/>
				</div>
				<div style="display: flex; flex-wrap: wrap; justify-content: flex-start; margin: -8px">
					<!-- Study Date -->
					<div style="margin: 8px;">
						<label>Study Date</label>
						<select v-model="filterPreset.studyDateRange" class="select">
							<option v-for="option in $options.dateRangeOptions" :key="option.value" :value="option.value">
								{{ option.label }}
							</option>
						</select>
					</div>
					<!-- Request Date -->
					<div style="margin: 8px;">
						<label>Request Date</label>
						<select v-model="filterPreset.requestDateRange" class="select">
							<option v-for="option in $options.dateRangeOptions" :key="option.value" :value="option.value">
								{{ option.label }}
							</option>
						</select>
					</div>
					<!-- Response Date -->
					<div style="margin: 8px;">
						<label>Response Date</label>
						<select v-model="filterPreset.responseDateRange" class="select">
							<option v-for="option in $options.dateRangeOptions" :key="option.value" :value="option.value">
								{{ option.label }}
							</option>
						</select>
					</div>
				</div>
			</div>
			<div class="footer" style="padding: 0 16px 16px 16px; justify-content: flex-end;">
				<button class="btn btn-default" @click="close">Cancel</button>
				<button class="btn btn-primary" style="min-width: 80px;" :disabled="!filterPreset.name.trim()" @click="submit">
					OK
				</button>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import { mapState } from 'vuex'
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
import DlgHeader from '@components/DlgHeader.vue'
import clone from '@utils/deepClone'
import AstRadio from '@components/Radio'
import ModalitySelect from '@components/ModalitySelect.vue'
import { dateRangeLabels } from '@utils/dateUtils'

const FilterPresetDlg = {
	name: 'FilterPresetDlg',
	components: {
		AstRadio,
		DlgHeader,
		ModalWrapper,
		ModalitySelect,
	},
	props: {
		currentPreset: {
			type: Object,
			default: undefined,
		},
	},
	dateRangeOptions: Object.entries(dateRangeLabels)
		.map(([value, label]) => ({ value, label }))
		.filter(({ label }) => !label.includes('Past')), // presets do not use the Past Week/Month/Year ranges
	data() {
		return {
			filterPreset: {},
		}
	},
	computed: {
		modalityId: {
			get() {
				if (!this.filterPreset.modality) return null
				const modality = this.$store.state.static.modalities.find(m => m.name === this.filterPreset.modality)
				return modality.id
			},
			set(id) {
				if (!id) return (this.filterPreset.modality = '')
				const modality = this.$store.state.static.modalities.find(m => m.id === id)
				this.filterPreset.modality = modality.name
			},
		},
		groupMembers() {
			const groupMembers = [
				{ id: null, name: ' ' },
				{ id: '00000000-0000-0000-0000-000000000001', name: 'Unclaimed' },
				...this.$store.state.consultant.groupMembers,
			]
			if (
				this.filterPreset.claimedBy &&
				!groupMembers.some(c => c.id === this.filterPreset.claimedBy)
			) {
				// add missing selected consultant to claimed-by dropdown
				const consultant = this.$store.state.static.consultants.find(
					c => c.id === this.filterPreset.claimedBy
				)
				if (consultant) groupMembers.push(consultant)
			}
			return groupMembers
		},
		...mapState({
			requestTypes: state =>
				Object.entries(state.consultant.requestTypes).sort((a, b) => {
					return a[1] < b[1] ? -1 : 1
				}),
			regions: state => state.consultant.regions,
			clientClinics: state => state.consultant.clientClinics,
			showTeleconsultationScheduleFiltering: state => state.static.permissions.showTeleconsultationScheduleFiltering,
		}),
	},
	created() {
		if (this.currentPreset) {
			this.filterPreset = clone(this.currentPreset)
		} else {
			this.filterPreset = {
				id: Date.now(),
				name: '',
				isComplete: 'Any',
				isDraft: 'Any',
				modality: '',
				studyDateRange: 'All',
				requestDateRange: 'All',
				responseDateRange: 'All',
				region: '',
				requestType: '00000000-0000-0000-0000-000000000000',
				claimedBy: '',
				requestedBy: '',
			}
		}
	},
	methods: {
		submit() {
			dlg.close(this, true, this.filterPreset)
		},
		close() {
			dlg.close(this)
		},
	},
}

export default FilterPresetDlg

export function openFilterPresetDlg(currentPreset) {
	return dlg.open(FilterPresetDlg, { currentPreset })
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.dialog.filter-preset {
	display: flex;
	position: relative;
	flex-direction: column;
	padding: 0;
	height: 100vh;
	width: 100vw;
	max-width: 100vw;
	@media (min-width: $mqSmall) {
		width: 540px;
		max-width: 90vw;
		height: 90vh;
		max-height: 90vh;
	}
	.filters {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		overflow: auto;
		padding: 0 16px 16px 16px;
		background: var(--primary-bg);
		border: 1px solid var(--primary-border);
		& > * {
			padding: 12px 0;
		}
		& > * label {
			display: block;
			user-select: none;
			font-size: 0.9em;
			font-weight: 400;
			padding-bottom: 4px;
		}
		.radio-group {
			display: flex;
			flex-wrap: wrap;
			@media (max-width: $mqSmall) {
				flex-direction: column;
				align-items: flex-start;
			}
			span {
				padding-bottom: 8px;
			}
		}
	}
}
</style>
