<template>
	<section class="study-folder-settings">
		<h4 style="margin-bottom: 16px">Study Folders</h4>
		<div
			v-if="!folders.length"
			style="display: flex; flex-direction: column; align-items: center; justify-content: center;"
		>
			<p>
				<svg-icon icon="info-circle" class="is-info" />
				Folders can be used to categorize your studies.
			</p>
			<svg-icon icon="folder-outline" style="margin: 16px; font-size: 4em; opacity: 0.3;" />
			<button class="btn btn-default" @click="addNewFolder">
				Add your first folder &hellip;
			</button>
		</div>
		<template v-if="folders.length">
			<p style="padding-bottom: 16px;">
				<svg-icon icon="info-circle" class="is-info" />
				These changes affect all users in your organization.
			</p>
			<div>
				<button class="btn btn-default" @click="addNewFolder">
					Add new folder &hellip;
				</button>
			</div>
			<reorderable-list v-model="folders" list-key="id" @input="save">
				<template #default="{ item: parent }">
					<span>{{ parent.name }}</span>
				</template>
				<template #buttons="{ item: parent }">
					<button title="Delete" class="btn btn-default" @click="deleteFolder(parent)">
						<svg-icon icon="delete" />
					</button>
					<button title="Edit" class="btn btn-default" @click="editFolder(parent)">
						<svg-icon icon="edit" />
					</button>
				</template>
				<template #inner="{ item:parent }">
					<reorderable-list
						v-if="parent.children && parent.children.length"
						v-model="parent.children"
						list-key="id"
						@input="save"
					>
						<template #default="{ item: child }">
							<svg-icon icon="folder-outline" style="opacity: 0.5; margin-right: 8px;" />
							<span>{{ child.name }}</span>
						</template>
						<template #buttons="{ item: child }">
							<button title="Delete" class="btn btn-default" @click="deleteFolder(child, parent.children)">
								<svg-icon icon="delete" />
							</button>
							<button title="Edit" class="btn btn-default" @click="editFolder(child, parent.children)">
								<svg-icon icon="edit" />
							</button>
						</template>
					</reorderable-list>
				</template>
			</reorderable-list>
		</template>
	</section>
</template>

<script>
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import { studyFolders } from '@services/folders'
import { openStudyFolder } from '@dialogs/StudyFolder.vue'
import ReorderableList from '@/components/ReorderableList.vue'

let saveDebounce

export default {
	name: 'StudyFoldersSettings',
	components: { ReorderableList },
	data() {
		return {
			studyFolders,
			folders: [],
		}
	},
	created() {
		this.folders = studyFolders.folders
			.filter(f => !f.parentFolderId)
			.map(f => ({
				...f,
				children: studyFolders.folders.filter(c => c.parentFolderId === f.id),
			}))
	},
	methods: {
		async addNewFolder() {
			const newFolder = await openStudyFolder()
			if (!newFolder) return
			newFolder.children = []
			// insert new folder at top of parent folder
			if (!newFolder.parentFolderId) this.folders.unshift(newFolder)
			else {
				const parentFolder = this.folders.find(f => f.id === newFolder.parentFolderId)
				parentFolder.children.unshift(newFolder)
			}
			this.save()
		},
		async deleteFolder(folder, folderArray) {
			// Use the root folder array if a parent's children array is not passed in
			if (!folderArray) folderArray = this.folders
			if (!(await showConfirm('Are you sure you want to delete this folder?'))) return
			const index = folderArray.indexOf(folder)
			try {
				folderArray.splice(index, 1)
				await studyFolders.delete(folder.id)
			} catch (err) {
				folderArray.splice(index, 0, folder) // restore folder if delete request fails
			}
		},
		async editFolder(folder, folderArray) {
			// Use the root folder array if a parent's children array is not passed in
			if (!folderArray) folderArray = this.folders
			const updatedFolder = await openStudyFolder(folder)
			if (!updatedFolder) return
			updatedFolder.children = studyFolders.folders.filter(c => c.parentFolderId === updatedFolder.id)
			// if folder's parent folder has not changed, replace at same position
			if (updatedFolder.parentFolderId === folder.parentFolderId) {
				folderArray.splice(folderArray.indexOf(folder), 1, updatedFolder)
			} else {
				// if parent folder has changed, remove existing folder, and insert updated folder at top
				folderArray.splice(folderArray.indexOf(folder), 1)
				if (!updatedFolder.parentFolderId) this.folders.unshift(updatedFolder)
				else {
					const parentFolder = this.folders.find(f => f.id === updatedFolder.parentFolderId)
					parentFolder.children.unshift(updatedFolder)
				}
			}
			this.save()
		},
		async save() {
			clearTimeout(saveDebounce)
			saveDebounce = setTimeout(async () => {
				try {
					const updatedStudyFolders = studyFolders.folders.slice()
					updatedStudyFolders.forEach(f => {
						// set each sequence number to the folder's new array index
						if (!f.parentFolderId) f.sequence = this.folders.findIndex(fo => fo.id === f.id)
						if (f.parentFolderId) {
							const parentFolder = this.folders.find(p => p.id === f.parentFolderId)
							if (parentFolder) f.sequence = parentFolder.children.findIndex(fo => fo.id === f.id)
						}
					})
					await studyFolders.save(updatedStudyFolders)
				} finally {
				}
			}, 50)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.study-folder-settings {
	display: flex;
	flex-direction: column;
	ol {
		max-width: 600px;
		padding: 16px 0;
		ol {
			margin: 8px -4px 0 -4px;
			padding: 4px 4px 4px 20px;
			background: var(--primary-bg);
			border: 1px solid var(--primary-border);
		}
	}
}
</style>
