<template>
	<ol class="reorderable-list">
		<transition-group name="rearrange-list">
			<li
				v-for="(item, i) in list"
				:key="listKey ? item[listKey] : item"
				style="word-break: break-word;"
				draggable
				@dragover.prevent="onDragOver($event, i)"
				@dragstart.stop="onDragStart($event, i)"
				@dragenter="onDragEnter($event, i)"
				@dragleave="onDragLeave($event, i)"
				@drop="onDrop($event, i)"
				@dragend="onDragEnd"
			>
				<div class="item">
					<svg-icon
						class="mouse-only"
						icon="drag-vertical"
						style="font-size: 1.2em; margin-right: 8px; opacity: 0.4;"
						inline
					/>
					<slot :item="item" :index="i">
						<span>{{ item }}</span>
					</slot>
					<div style="white-space: nowrap; margin-left: 4px;">
						<slot name="buttons" :item="item" :index="i"></slot>
						<button class="btn btn-default" title="Move Up" :disabled="i === 0" @click="moveItemUp(i)">
							<svg-icon icon="caret-up" />
						</button>
						<button
							class="btn btn-default"
							title="Move Down"
							:disabled="i === list.length - 1"
							@click="moveItemDown(i)"
						>
							<svg-icon icon="caret-down" />
						</button>
					</div>
				</div>
				<slot name="inner" :item="item"></slot>
			</li>
		</transition-group>
	</ol>
</template>

<script>
export default {
	name: 'ReorderableList',
	props: {
		value: {
			type: Array,
			required: true,
		},
		listKey: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			draggingIndex: null,
			dragOverIndex: null,
		}
	},
	computed: {
		list: {
			get() {
				return this.value
			},
			set(list) {
				this.$emit('input', list)
			},
		},
	},
	methods: {
		moveItem({ from, to }) {
			let list = this.list.slice()
			list.splice(to, 0, list.splice(from, 1)[0])
			this.list = list
		},
		moveItemUp(i) {
			this.moveItem({ from: i, to: i - 1 })
		},
		moveItemDown(i) {
			this.moveItem({ from: i, to: i + 1 })
		},
		onDragOver(e, i) {
			e.dataTransfer.dropEffect = 'move'
		},
		onDragEnter(e, i) {
			if (this.draggingIndex === null) return
			if (this.draggingIndex !== i) {
				e.target.classList.add(i < this.draggingIndex ? 'drop-before' : 'drop-after')
				this.dragOverIndex = i
			}
		},
		onDragLeave(e) {
			e.target.classList.remove('drop-before', 'drop-after')
			this.dragOverIndex = null
		},
		onDrop(e, i) {
			if (this.draggingIndex === null) return
			e.target.classList.remove('drop-before', 'drop-after')
			this.moveItem({ from: this.draggingIndex, to: i })
		},
		onDragStart(e, i) {
			this.draggingIndex = i
		},
		onDragEnd() {
			this.draggingIndex = null
			this.dragOverIndex = null
		},
	},
}
</script>

<style lang="scss">
ol.reorderable-list {
	list-style-type: none;
	.rearrange-list-move {
		transition: transform 0.2s ease;
	}
	li {
		background: var(--secondary-bg);
		border: 1px solid var(--secondary-border);
		padding: 8px 8px;
		margin: 2px 0;
		user-select: none;
		font-size: 0.9em;
		cursor: grab;
		.item {
			display: flex;
			align-items: center;
			position: relative;
			span {
				flex-grow: 1;
			}
			button {
				font-size: 0.8em;
				& + button {
					margin-left: 8px;
				}
			}
		}
		&.drop-after {
			box-shadow: 0 2px 0 0 var(--icon-success);
			& > * {
				pointer-events: none;
			}
		}
		&.drop-before {
			box-shadow: 0 -2px 0 0 var(--icon-success);
			& > * {
				pointer-events: none;
			}
		}
	}
}
</style>
